import { Injectable } from '@angular/core';
import cryptoJS from 'crypto-js';
import { LocalStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppCommonModule } from '@/common/common.module';
import { environment } from '@environments/environment';

@Injectable({ providedIn: AppCommonModule })
export class StorageService {
  constructor(
    private _localStorage: LocalStorageService,
  ) {
  }

  clear(key?: string): void {
    this._localStorage.clear(key);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  observe(key: string): Observable<any> {
    return this._localStorage.observe(key).pipe(
      map((encryptedData: string) => {
        if (encryptedData) {
          return this.decryptData(encryptedData);
        }

        return null;
      }),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  retrieve(key: string): any {
    const encryptedData = this._localStorage.retrieve(key);
    if (encryptedData) {
      return this.decryptData(encryptedData);
    }

    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store(key: string, value: any): void {
    const encryptedData = this.encryptData(value);

    this._localStorage.store(key, encryptedData);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private encryptData(data: any): string {
    const encryptedData = cryptoJS.AES.encrypt(JSON.stringify(data), environment.STORAGE_SECRET_KEY);

    return encryptedData.toString();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private decryptData(encryptedData: string): any {
    const bytes = cryptoJS.AES.decrypt(encryptedData, environment.STORAGE_SECRET_KEY);

    return JSON.parse(bytes.toString(cryptoJS.enc.Utf8));
  }
}
