import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService as PrimeNGMessageService } from 'primeng/api';
// eslint-disable-next-line import/no-unresolved
import { Message } from 'primeng/api/message';
import { AppCommonModule } from '@/common/common.module';

@Injectable({ providedIn: AppCommonModule })
export class MessageService {
  constructor(
    private _messageService: PrimeNGMessageService,
    private _translate: TranslateService,
  ) {
  }

  add(message: Message): void {
    this._messageService.add({
      ...message,
      summary: this._translate.instant(message.summary ?? ''),
      detail: this._translate.instant(message.detail ?? ''),
    });
  }

  addAll(messages: Message[]): void {
    this._messageService.addAll(
      messages.map(message => ({
        ...message,
        summary: this._translate.instant(message.summary ?? ''),
        detail: this._translate.instant(message.detail ?? ''),
      })),
    );
  }
}
